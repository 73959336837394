@import '../../global/styles';

.editor {
  :global(.ace_placeholder) {
    font-size: 12px;
    letter-spacing: 0.5px;
    line-height: 16px;
    left: -5px;
    transform: unset;
  }
}
